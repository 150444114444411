.container {
  width: 100%;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .title {
    font-size: 24px;
    font-weight: 700;
  }
}